@font-face {
  font-family: "Helvetica Neue";
  src: url("/public/fonts/HelveticaNeue.woff2") format("woff2"), url("/public/fonts/HelveticaNeue.woff") format("woff");
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1d521e !important;
  color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wizard-container {
  position: relative;
}

@media (min-width: 993px) {
  .wizard-container {
    position: relative;
    max-width: 50vh !important;
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  padding-top: 3em;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.step-body {
  padding-left: 2.5em;
  padding-right: 2.5em;
}

.step-no-padding .step-body {
  padding-left: 0;
  padding-right: 0;
}

header {
  flex-grow: 0;
}
.header-image {
  width: 80%;
  margin-top: 1em;
  margin-bottom: 1em;
}

footer {
  width: 100%;
  flex-grow: 0;
}

footer img {
  width: 92%;
}

body.fondo-1 {
  background-color: #1d521e !important;
}

body.fondo-2 {
  background-color: #1d521e !important;
}

body.fondo-3 {
  background-color: #104B70 !important;
}

body.fondo-2 .wizard-container {
  background-image: url(/public/img/fondo2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.w-decor {
  width: 80%;
}

.w-decor-lg {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.decor5 {
  position: relative;
}
.decor5::after {
  content: "";
  background-image: url(/public/img/decor5l.png),  url(/public/img/decor5r.png);
  background-repeat: no-repeat, no-repeat;
  background-size: 14% auto,  14% auto;
  background-position: top left, top right;
  position: absolute;
  width: 100%;
  height: 100%;
  top: -16%;
  left: 0;
}


.marco-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.marco-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.marco-left {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 110;
}

.marco-right {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 110;
}

strong {
  color: #FFABAC;
}

.error-message {
  color: #FFABAC;
}

p {
  font-size: 1.2em;
}

input.form-control {
  background-color: transparent !important;
  border: 0px !important;
  background-image: url(/public/img/inputlg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  min-width: 10em;
  max-width: 14em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 1.15em;
}

button.btn-secondary {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #fff !important;
}

button.btn-primary {
  background-color: transparent !important;
  border: 0px !important;
  background-image: url(/public/img/button.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: #1D4F1E !important;
  min-width: 6.0em;
  position: relative;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  text-align: center;
  font-size: 1.15em;
}

button.btn-primary.btn-text-small {
  font-size: 0.75rem;
  line-height: 1.2em;
  padding-left: 2.5em;
  padding-right: 2.5em;
  background-image: url(/public/img/btn-final.png);
}

button.btn-primary.with-decorator::after {
  content: "";
  background-image: url(/public/img/button-decorator.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: absolute;
  bottom: -1.3em;
  left: -25%;
  right: 0;
  height: 1.9em;
  width: 150%;
}

.btn-maquillaje {
  width: 100%;
  min-width: auto !important;
  background-color: transparent !important;
  background-image: none !important;
  padding: 0 !important;
  margin-bottom: 15px;
}

.btn-maquillaje>img {
  width: 100%;
}

.image-wrapper {
  position: relative;
  width: 87%;
  max-width: 600px;
  height: auto;
  overflow: hidden;
  margin: auto;
}

.image-wrapper>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(/public/img/watermark.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.image-wrapper>.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mtm {
  margin-top: -0.5em;
}

.text-sm {
  font-size: 0.80rem;
}


@media (max-width: 375px) {
  body {
    font-size: 14px;
  }
  .step {
    padding-top: 1.5em;
  }

  button.btn-primary {
    font-size: 1em;
  }

  input.form-control {
    font-size: 1em;
  }

  p {
    font-size: 1.0em;
    line-height: 1.2em;
  }

  footer img {
    width: 80%;
  }

  .w-decor {
    width: 70%;
  }

  button.btn-primary.btn-text-small {
    font-size: 0.70rem;
    line-height: 1.1em;
    padding-left: 2.0em;
    padding-right: 2.0em;
  }

  .text-sm {
    font-size: 0.70rem;
  }
}